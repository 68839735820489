import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Upload, Button, Icon, Switch, Divider, message } from "antd";
import { ChromePicker } from "react-color";
import { connect } from "react-redux";
import {
  deleteAirline,
  uploadFile,
  insertAirline,
  updateAirline,
  deleteFile
} from "../../../redux/actions/setting";
import { Constraints } from "./Constraints";
import validatejs from "validate.js";
import { getAirline, getCountry } from "../../../redux/actions/dropdown";

class AirlineEdit extends Component {
  state = {
    form: {},
    fileList: [],
    errors: undefined,
    disabledSave: false,
    filterCountry: []
  };

  componentDidMount() {
    const { selected } = this.props;
    let { form, fileList } = this.state;
    form = selected || { display: true };
    if (form.imgName && form.imgName !== "")
      fileList.push({
        isExist: true,
        uid: "-1",
        name: form.imgName.split("/").pop(),
        status: "done",
        url: form.imgName
      });
    this.setState({ form, fileList, errors: validatejs(form, Constraints) });
    this.setCountry();
  }

  setCountry = () => {
    this.props.getCountry().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterCountry: data
        });
      }
    });
  };

  onChange = ({ name, value, error }) => {
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors });
  };

  handleUpload = ({ fileList, file }) => {
    if (fileList.length === 0) {
      let { form } = this.state;
      const body = { typeFile: 4, deleteId: form.airlineId };
      this.props.deleteFile(body).then(res => {
        if (res.payload && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          message.success("Delete success");
          this.setState({ fileList });
        }
      });
    } else {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width > 880 || img.height > 365) {
          return message.error("Maximum upload image 880 x 365 pixel!");
        }
        const isLt2M =
          fileList[fileList.length - 1].originFileObj.size / 1024 / 1024 < 2;
        if (!isLt2M) return message.error("File must smaller than 2MB!");
        this.setState({ fileList: [fileList[fileList.length - 1]] });
      }
    }
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form, fileList } = this.state;
      const { selected } = this.props;
      let body = {
        airlineCodeIcao: form.icao || "",
        airlineCodeIata: form.iata || "",
        airlineName: form.airline || "",
        airlineCompany: form.companyName || "",
        airlineCountryId: form.countryId || "",
        airlineStatus: form.display ? 1 : 0,
        color: form.color || "#C8C4D2",
        airlineUpdateFlag: "I"
      };
      if (fileList.length === 1) {
        if (fileList[0].isExist !== true)
          body.files = fileList[0].originFileObj;
      }
      if (selected) {
        // update
        body.airlineId = form.airlineId;
        body.airlineUpdateFlag = "U";
        this.props.updateAirline(body).then(res => {
          this.setState({ disabledSave: false });
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 200 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Update Airline " + body.airlineName + " success");
            this.props.getAirline();
            this.props.onRefresh(this.props.name);
          }
        });
      } else {
        this.props.insertAirline(body).then(res => {
          this.setState({ disabledSave: false });
          if (res.status === 400) {
            message.error("ไม่สามารถเพิ่มข้อมูลได้");
          } else if (
            res &&
            res.payload.status === 201 &&
            res.type.endsWith("SUCCESS")
          ) {
            message.success("Insert Airline " + body.airlineName + " success");
            this.props.getAirline();
            this.props.onRefresh(this.props.name);
          }
        });
      }
    });
  };

  render() {
    const { form, fileList, errors, disabledSave, filterCountry } = this.state;
    let template = [
      {
        type: "input",
        name: "airline",
        label: "Airline",
        value: form.airline,
        placeholder: ""
      },
      {
        type: "input",
        name: "icao",
        label: "ICAO",
        value: form.icao,
        placeholder: ""
      },
      {
        type: "input",
        name: "iata",
        label: "IATA",
        value: form.iata,
        placeholder: ""
      },
      {
        type: "input",
        name: "companyName",
        label: "Company Name",
        value: form.companyName,
        placeholder: ""
      },
      {
        type: "select",
        name: "countryId",
        label: "Country",
        value: form.countryId,
        placeholder: "Select...",
        options: {
          name: "countryOptions",
          id: "countryId",
          text: "countryName",
          dataLoad: 1,
          dataService: filterCountry
        }
      }
    ];
    template.forEach(x => (x.constraint = Constraints));
    return (
      <div className="card card-body card-body-component">
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12">
              <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                {data.label} :
              </label>
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}

        <div className="form-row form-inline mb-2 col-12 d-flex align-items-start">
          <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
            Logo :
          </label>
          <div className="col-lg-6 col-md-8 col-sm-8 col-12">
            <Upload
              accept={"image/*"}
              listType={"picture"}
              beforeUpload={() => false}
              onChange={this.handleUpload}
              fileList={fileList}
            >
              <Button>
                <Icon type="upload" /> Upload
              </Button>
              <div className="text-warning">
                Only JPG, PNG and JPEG file are allowed
              </div>
              <div className="text-warning">
                Maximum upload file size 2 MB, and 880 x 365 pixel
              </div>
            </Upload>
          </div>
        </div>

        <div className="form-row form-inline mb-2 col-12">
          <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
            Color :
          </label>
          <div className="col-lg-4 col-md-6 col-sm-8 col-12">
            <ChromePicker
              color={form.color}
              onChangeComplete={({ hex }) =>
                this.onChange({ name: "color", value: hex })
              }
            />
          </div>
        </div>

        <div className="form-row form-inline mb-2 col-12">
          <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
            Display (On/Off) :
          </label>
          <div className="col-lg-4 col-md-6 col-sm-8 col-12">
            <Switch
              checked={form.display}
              onChange={checked => {
                let { form } = this.state;
                form.display = checked;
                this.setState({ form });
              }}
            />
          </div>
        </div>

        <Divider />

        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  deleteAirline: id => dispatch(deleteAirline(id)),
  uploadFile: param => dispatch(uploadFile(param)),
  insertAirline: body => dispatch(insertAirline(body)),
  updateAirline: body => dispatch(updateAirline(body)),
  deleteFile: param => dispatch(deleteFile(param)),
  getAirline: () => dispatch(getAirline()),
  getCountry: () => dispatch(getCountry())
});

export default connect(mapStateToProps, mapDispatchToProps)(AirlineEdit);
