import React, { Component } from "react";
import { Switch, Divider, Popover, message, Spin, Icon } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isReadOnly } from "../../../utils/permission";
import Render from "../../../components/Renderer";
import {
  getListSettingAirtype,
  deleteAirType,
  updateDisplayStatusAirType
} from "../../../redux/actions/setting";
import { getAirTypeInSetting } from "../../../redux/actions/dropdown";
import { Link } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
//import context from '../../../utils/language'

class AirType extends Component {
  state = {
    form: {},
    data: [],
    visible: {},
    isLoading: false
  };

  componentDidMount() {
    this.getList();
  }

  getList = param => {
    this.setState({ isLoading: true });
    this.props.getListSettingAirtype(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        const data = res.payload.data.data;
        let array = [],
          distinct = {};
        for (let item of data) {
          if (distinct[item.airTypeCode] === undefined)
            array.push({
              airTypeId: item.airTypeId,
              airtype: item.airTypeCode,
              display: item.isEnable === 1,
              updateDate: item.dateUpdate,
              list: data
              .filter(x => x.airTypeCode === item.airTypeCode)
              .map(x => ({
                  aircraftWidth: x.aircraftWidth,
                  aircraftLength: x.aircraftLength,
                  airTypeId: x.airTypeId,
                  grossWeight: x.grossWeight,
                  pax: x.pax,
                  isEnable: item.isEnable === 1,
                }))
            });
          distinct[item.airTypeCode] = true;
        }
        array.sort((a, b) => a.airtype - b.airtype);
        array.forEach((x, i) => {
          x.no = i + 1;
          x.key = i;
        });
        this.setState({ data: array });
      }
    });
  };

  edit = item => {
    this.props.onEdit(this.props.name, item);
  };
  add = () => this.props.onAdd(this.props.name);

  onDelete = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible, data: [] });
    this.props.deleteAirType(item.airtype).then(res => {
      if (res.status === 400) {
        message.error("ข้อมูลมีการใช้งานอยู่ ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Air Type " + item.airtype + " success");
        this.getList();

        this.props.getAirTypeInSetting();
      }
      this.search();
    });
  };

  search = () => {
    let { form } = this.state;
    let param = "";
    param += "airtypeCode=" + (form.airTypeCode || "");
    param += "&grossWeight=" + (form.grossweight || "");
    param += "&pax=" + (form.pax || "");
    this.getList(param);
  };

  onChange = ({ name, value }) => {
    let { form } = this.state;
    form[name] = value;
    this.setState({ form, data: [] }, this.search);
  };

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  onCheckChange = (item, checked) => {
    const body = {
      airTypeId: item.airTypeId,
      airtypeCode: item.airtype,
      statusDisplay: checked ? 1 : 0
    };
    this.props.updateDisplayStatusAirType(body).then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        message.success("Update status success");
        this.props.getAirTypeInSetting();
      }
      this.search();
    });
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item.key] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data, isLoading } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    //const { language } = this.props
    //const name = context[language].viewStatModal
    const readOnly = isReadOnly("airtypesetting");
    const template = [
      {
        type: "select",
        name: "airTypeCode",
        value: form.airTypeCode,
        placeholder: "Select Air Type...",
        options: {
          name: "airtypeInSettingOption",
          id: "airtypeCode",
          text: "airtypeCode"
        }
      },
      {
        type: "search",
        name: "grossweight",
        value: form.grossweight,
        placeholder: "Gross Weight..."
      },
      {
        type: "search",
        name: "pax",
        value: form.pax,
        placeholder: "Pax..."
      }
    ];
    let columns = [
      {
        title: "#",
        key: "no",
        dataIndex: "no"
      },
      {
        title: "Air Type",
        dataIndex: "airtype",
        key: "airtype"
      },
      {
        title: "Display(On/Off)",
        key: "display",
        render: item => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.onCheckChange(item, checked)}
            checked={item.display}
          />
        )
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate"
      },
      {
        title: "Edit",
        key: "edit",
        render: item => (
          <button
            disabled={readOnly}
            onClick={() => this.edit(item)}
            type="button"
            className="btn btn-outline-success btn-sm"
          >
            <FontAwesomeIcon icon="pen" />
          </button>
        )
      },
      {
        title: "Delete",
        key: "delete",
        render: item =>
          readOnly ? (
            <button
              disabled
              type="button"
              className="btn btn-outline-danger btn-sm"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[item.key]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[item.key] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => this.onDelete(item)}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(item)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                disabled={readOnly}
                type="button"
                className="btn btn-outline-danger btn-sm"
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )
      }
    ];

    columns.forEach(x => {
      x.onHeaderCell = () => ({
        className: "ant-table-column-has-actions ant-table-column-has-sorters"
      });
      x.sorter = (a, b) => {
        try {
          if (typeof a[x.dataIndex] === "number")
            return a[x.dataIndex] - b[x.dataIndex];
          return a[x.dataIndex].localeCompare(b[x.dataIndex]);
        } catch (e) {}
      };
    });

    const expandIcon = ({ expanded, expandable, record, onExpand }) => {
      if (
        !expandable ||
        record.isHeader ||
        !record.list ||
        (record.list && record.list.length === 0)
      )
        return null;
      return (
        <Link to="#" onClick={e => onExpand(record, e)}>
          {expanded ? (
            <Icon type="minus-square" />
          ) : (
            <Icon type="plus-square" />
          )}
        </Link>
      );
    };

    const table = (
      <CustomTable
        expandIcon={expandIcon}
        scroll={{ x: "1500px" }}
        pagination={{
          defaultPageSize: 10,
          position: "bottom"
        }}
        footer={
          isLoading
            ? () => (
                <div className="text-center">
                  <Spin className="pr-2" />
                  loading...
                </div>
              )
            : null
        }
        className="tbody-center bg-white"
        columns={columns}
        expandedRowRender={record => {
          return record.list.map((l, i) => (
            <div className="form-inline mb-2" key={i}>
              <div className="col-md-1 text-right">
                {i + 1}
                <Divider type="vertical" />
              </div>
              <div className="col-md-3 text-left">
                Gross Weight :{l.grossWeight}
              </div>
              <div className="col-md-2 text-left">Pax :{l.pax}</div>
            </div>
          ));
        }}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-header-table">
        <div className="form-row form-inline col-md-12 mb-3 px-0">
          <div className="col-md-3">
            {!readOnly && (
              <button
                type="button"
                onClick={this.add}
                className="btn btn-success"
              >
                <FontAwesomeIcon icon="plus" /> Add Air Type
              </button>
            )}
          </div>
          <div className="col-md-9 form-inline justify-content-end px-0">
            {template.map((t, i) => (
              <div className="col-md-2 justify-content-end pr-0" key={i}>
                {Render(t, this.onChange)}
              </div>
            ))}
            <button
              onClick={() => this.setState({ form: {} }, this.search)}
              className="btn btn-sm btn-dark ml-2"
            >
              <FontAwesomeIcon icon="redo" />
              <span className="ml-2">clear</span>
            </button>
          </div>
        </div>
        {table}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListSettingAirtype: param => dispatch(getListSettingAirtype(param)),
  deleteAirType: id => dispatch(deleteAirType(id)),
  getAirTypeInSetting: () => dispatch(getAirTypeInSetting()),
  updateDisplayStatusAirType: body => dispatch(updateDisplayStatusAirType(body))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AirType);
