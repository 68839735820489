var querystring = require("querystring");

const options = {
  onError({ getState, dispatch, error }) {
    let text = "Request error";
    if (
      error &&
      error.request &&
      error.request.response &&
      error.request.status !== 400
    ) {
      text = error.request.response.data;
      dispatch({ type: "REQUEST_ERROR", data: text, error });
    } else {
      let obj = {
        status: error.request.status,
        data: error.request.response.data
      };
      return obj;
    }
  }
};

export const getListSettingAirport = param => {
  return {
    type: "GET_LIST_SETTING_AIRPORT",
    payload: {
      request: {
        url: "/Setting/v1/listSettingAirport?" + (param || "")
      },
      options: options
    }
  };
};

export const getListSettingAirline = param => {
  return {
    type: "GET_LIST_SETTING_AIRLINE",
    payload: {
      request: {
        url: "/Setting/v1/listSettingAirline?" + (param || "")
      },
      options: options
    }
  };
};

export const getListSettingCallsign = param => {
  return {
    type: "GET_LIST_SETTING_CALLSIGN",
    payload: {
      request: {
        url: "/Setting/v1/listSettingCallsign?" + (param || "")
      },
      options: options
    }
  };
};

export const getListSettingAirtype = param => {
  return {
    type: "GET_LIST_SETTING_AIRTYPE",
    payload: {
      request: {
        url: "/Setting/v1/listSettingAirType?" + (param || "")
      },
      options: options
    }
  };
};

export const getListSettingTerminal = param => {
  return {
    type: "GET_LIST_SETTING_TERMINAL",
    payload: {
      request: {
        url: "/Setting/v1/listSettingTerminal?" + (param || "")
      },
      options: options
    }
  };
};

export const getListSettingParkingBay = param => {
  return {
    type: "GET_LIST_SETTING_PARKING_BAY",
    payload: {
      request: {
        url: "/Setting/v1/listSettingParkingBay?" + (param || "")
      },
      options: options
    }
  };
};

export const getListSettingParkingSlot = param => {
  return {
    type: "GET_LIST_SETTING_PARKING_SLOT",
    payload: {
      request: {
        url: "/Setting/v1/listSettingParkingSlot?" + (param || "")
      },
      options: options
    }
  };
};

export const getListSettingZoneNo = param => {
  return {
    type: "GET_LIST_SETTING_ZONE_NO",
    payload: {
      request: {
        url: "/Setting/v1/listSettingZone?" + (param || "")
      },
      options: options
    }
  };
};

export const getListSettingGate = param => {
  return {
    type: "GET_LIST_SETTING_GATE",
    payload: {
      request: {
        url: "/Setting/v1/listSettingGate?" + (param || "")
      },
      options: options
    }
  };
};

export const deleteAirline = id => {
  return {
    type: "DELETE_AIRLINE",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteAirline?airlineId=" + id
      },
      options: options
    }
  };
};

export const insertCallsign = body => {
  return {
    type: "INSERT_CALLSIGN",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertCallsign",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateCallsign = body => {
  return {
    type: "UPDATE_CALLSIGN",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateCallsign",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const deleteCallsign = id => {
  const paramBody = { callsignId: id };
  return {
    type: "DELETE_CALLSIGN",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/delCallsign",
        data: querystring.stringify(paramBody)
      },
      options: options
    }
  };
};

export const deleteAirport = id => {
  const paramBody = { airPortId: id };
  return {
    type: "DELETE_AIRPORT",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteAirport",
        data: querystring.stringify(paramBody)
      },
      options: options
    }
  };
};

export const insertZoneNo = body => {
  return {
    type: "INSERT_ZONENO",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertZone",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateZoneNo = body => {
  return {
    type: "UPDATE_ZONENO",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/UpdateZone",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const deleteZoneNo = id => {
  const paramBody = { zoneId: id };
  return {
    type: "DELETE_ZONENO",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteZone",
        data: querystring.stringify(paramBody)
      },
      options: options
    }
  };
};

export const deleteCountryInZone = id => {
  const paramBody = { countryId: id };
  return {
    type: "DELETE_ZONENO",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteCountryInZone",
        data: querystring.stringify(paramBody)
      },
      options: options
    }
  };
};

export const deleteTerminal = id => {
  return {
    type: "DELETE_TERMINAL",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteTerminal?terminalId=" + id
      },
      options: options
    }
  };
};

export const insertAirType = body => {
  return {
    type: "INSERT_AIRTYPE",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertAirType",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateAirType = body => {
  return {
    type: "updateAirType",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateAirType",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const uploadFile = param => {
  var bodyFormData = new FormData();
  for (let key in param) bodyFormData.set(key, param[key]);
  return {
    type: "uploadFile",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/UploadFiles",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: bodyFormData
      },
      options: options
    }
  };
};

export const deleteFile = param => {
  var bodyFormData = new FormData();
  for (let key in param) bodyFormData.set(key, param[key]);
  return {
    type: "deleteFile",
    payload: {
      request: {
        method: "PUT",
        url: "/FilesUpload/v1/DeleteFile",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: bodyFormData
      },
      options: options
    }
  };
};

export const insertAirline = param => {
  var bodyFormData = new FormData();
  for (let key in param) bodyFormData.set(key, param[key]);
  return {
    type: "insertAirline",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertAirline",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: bodyFormData
      },
      options: options
    }
  };
};

export const updateAirline = param => {
  var bodyFormData = new FormData();
  for (let key in param) bodyFormData.set(key, param[key]);
  return {
    type: "updateAirline",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateAirline",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: bodyFormData
      },
      options: options
    }
  };
};

export const getListSettingFlightStatus = param => {
  return {
    type: "listSettingFlightStatus",
    payload: {
      request: {
        url: "/Setting/v1/listSettingFlightStatus?" + (param || "")
      },
      options: options
    }
  };
};

export const getListSettingTraffType = param => {
  return {
    type: "listSettingTraffType",
    payload: {
      request: {
        url: "/Setting/v1/listSettingTraffType?" + (param || "")
      },
      options: options
    }
  };
};

export const insertTraffType = body => {
  return {
    type: "insertTraffType",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertTraffType",
        headers: {
          "Content-Type": "application/json"
        },
        data: body
      },
      options: options
    }
  };
};

export const updateTraffType = body => {
  return {
    type: "updateTraffType",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateTraffType",
        headers: {
          "Content-Type": "application/json"
        },
        data: body
      },
      options: options
    }
  };
};

export const deleteTraffType = id => {
  return {
    type: "deleteTraffType",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteTraffType?traffTypeId=" + id
      },
      options: options
    }
  };
};

export const getListSettingManageRelate = param => {
  return {
    type: "listSettingManageRelate",
    payload: {
      request: {
        url: "/Setting/v1/listSettingManageRelate?" + (param || "")
      },
      options: options
    }
  };
};

export const deleteAirType = id => {
  const body = {
    airTypeId: id
  };
  return {
    type: "deleteAirType",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteAirType",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const insertManageRelation = body => {
  return {
    type: "insertManageRelation",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertManageRelation",
        headers: {
          "Content-Type": "application/json"
        },
        data: body
      },
      options: options
    }
  };
};

export const updateManageRelation = body => {
  return {
    type: "updateManageRelation",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateManageRelation",
        headers: {
          "Content-Type": "application/json"
        },
        data: body
      },
      options: options
    }
  };
};

export const deleteManageRelation = body => {
  return {
    type: "deleteManageRelation",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteManageRelation",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const insertTerminal = body => {
  return {
    type: "insertTerminal",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertTerminal",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateTerminal = body => {
  return {
    type: "updateTerminal",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateTerminal",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const getListSettingRegion = param => {
  return {
    type: "listSettingRegion",
    payload: {
      request: {
        url: "/Setting/v1/listSettingRegion?" + (param || "")
      },
      options: options
    }
  };
};

export const deleteRegion = id => {
  const body = { regionId: id };
  return {
    type: "deleteRegion",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteRegion",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const insertRegion = body => {
  return {
    type: "insertRegion",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertRegion",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateRegion = body => {
  return {
    type: "UpdateRegion",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/UpdateRegion",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const delSettingFlightStatus = id => {
  const paramBody = { configFlightStatusId: id };
  return {
    type: "delSettingFlightStatus",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/delSettingFlightStatus",
        data: querystring.stringify(paramBody)
      },
      options: options
    }
  };
};

export const insertSettingFlightStatus = body => {
  return {
    type: "insertSettingFlightStatus",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertSettingFlightStatus",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateSettingFlightStatus = body => {
  return {
    type: "updateSettingFlightStatus",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateSettingFlightStatus",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const getListSettingBelt = param => {
  return {
    type: "listSettingBelt",
    payload: {
      request: {
        url: "/Setting/v1/listSettingBelt?" + (param || "")
      },
      options: options
    }
  };
};

export const insertBelt = body => {
  return {
    type: "insertBelt",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertBelt",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateBelt = param => {
  return {
    type: "updateBelt",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateBelt?" + param,
        headers: {
          "Content-Type": "application/json"
        },
        data: null
      },
      options: options
    }
  };
};

export const deleteBelt = id => {
  return {
    type: "deleteBelt",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteBelt?beltId=" + id,
        headers: {
          "Content-Type": "application/json"
        },
        data: null
      },
      options: options
    }
  };
};

export const insertAirport = body => {
  return {
    type: "insertAirport",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertAirport",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateAirport = body => {
  return {
    type: "updateAirport",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateAirport",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const deleteGate = id => {
  const body = { gateId: id };
  return {
    type: "deleteGate",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteGate",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const insertGate = body => {
  return {
    type: "insertGate",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertGate",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateGate = body => {
  return {
    type: "updateGate",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/UpdateGate",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const deleteParkingBay = id => {
  const body = { parkingBayId: id };
  return {
    type: "deleteParkingBay",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteParkingBay",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const insertParkingBay = body => {
  return {
    type: "insertParkingBay",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertParkingBay",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateParkingBay = body => {
  return {
    type: "updateParkingBay",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateParkingBay",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const deleteParkingSlot = id => {
  const body = { parkingSlotId: id };
  return {
    type: "deleteParkingSlot",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteParkingSlot",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const insertParkingSlot = body => {
  return {
    type: "insertParkingSlot",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertParkingSlot",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateParkingSlot = body => {
  return {
    type: "updateParkingSlot",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateParkingSlot",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateDisplayStatusAirport = body => {
  return {
    type: "UpdateDisplayStatusAirport",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/UpdateDisplayStatusAirport",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateStatusRegion = body => {
  return {
    type: "updateStatusRegion",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateStatusRegion",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateStatusTerminal = body => {
  return {
    type: "updateStatusTerminal",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateStatusTerminal",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateStatusDisplatBelt = body => {
  return {
    type: "updateStatusDisplatBelt",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateStatusDisplatBelt",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateStatusDisplayAirline = body => {
  return {
    type: "updateStatusDisplayAirline",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateStatusDisplayAirline",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateStatusGate = body => {
  return {
    type: "updateStatusGate",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateStatusGate",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateDisplayStatusParkingBay = body => {
  return {
    type: "UpdateDisplayStatusParkingBay",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/UpdateDisplayStatusParkingBay",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateDisplayStatusParkingSlot = body => {
  return {
    type: "updateDisplayStatusParkingSlot",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateDisplayStatusParkingSlot",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateDisplayCallsign = body => {
  return {
    type: "updateDisplayCallsign",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateDisplayCallsign",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateDisplayStatusAirType = body => {
  return {
    type: "updateDisplayStatusAirType",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateDisplayStatusAirType",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const listSettingAirportType = param => {
  return {
    type: "listSettingAirportType",
    payload: {
      request: {
        url: "/Setting/v1/listSettingAirportType?" + (param || "")
      },
      options: options
    }
  };
};

export const deleteAirportType = id => {
  return {
    type: "deleteAirportType",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteAirportType?airportTypeId=" + id,
        data: null
      },
      options: options
    }
  };
};

export const updateStatusAirportType = (id, status) => {
  return {
    type: "updateStatusAirportType",
    payload: {
      request: {
        method: "PUT",
        url:
          "/Setting/v1/updateStatusAirportType?airportTypeId=" +
          id +
          "&statusDisplay=" +
          status,
        data: null
      },
      options: options
    }
  };
};

export const insertAirportType = body => {
  return {
    type: "insertAirportType",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertAirportType",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateAirportType = body => {
  return {
    type: "updateAirportType",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateAirportType",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateDisplaySettingFlightStatus = body => {
  return {
    type: "updateDisplaySettingFlightStatus",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateDisplaySettingFlightStatus",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateDisplayparentFlightStatus = body => {
  return {
    type: "updateDisplayparentFlightStatus",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateDisplayparentFlightStatus",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateStatusTraffType = body => {
  return {
    type: "updateStatusTraffType",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateStatusTraffType",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const listSettingCounter = param => {
  return {
    type: "listSettingCounter",
    payload: {
      request: {
        url: "/Setting/v1/listSettingCounter?" + (param || "")
      },
      options: options
    }
  };
};

export const deleteCounter = id => {
  return {
    type: "deleteCounter",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteCounter?counterId=" + id,
        data: null
      },
      options: options
    }
  };
};

export const insertCounter = body => {
  return {
    type: "insertCounter",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertCounter",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateCounter = body => {
  return {
    type: "updateCounter",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateCounter",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const getByTimeId = () => {
  return {
    type: "getByTimeId",
    payload: {
      request: {
        url: "/Setting/v1/getByTimeId"
      },
      options: options
    }
  };
};

export const insertTimeCounterBeltFlight = body => {
  return {
    type: "insertTimeCounterBeltFlight",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertTimeCounterBeltFlight",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const updateAirTypeStandAlone = body => {
  return {
    type: "updateAirTypeStandAlone",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateAirtypeStandAlone",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const getListSettingCounterCheckIn = param => {
  return {
    type: "GET_LIST_SETTING_COUNTER_CHECK_IN",
    payload: {
      request: {
        url: "/Setting/v1/listSettingCounterCheckIn?" + (param || "")
      },
      options: options
    }
  };
};


export const deleteCounterCheckIn = id => {
  const body = { gateId: id };
  return {
    type: "deleteCounterCheckIn",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/deleteCounterCheckIn",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};

export const insertCounterCheckIn = body => {
  return {
    type: "insertCounterCheckIn",
    payload: {
      request: {
        method: "POST",
        url: "/Setting/v1/insertCounterCheckIn",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(body)
      },
      options: options
    }
  };
};

export const updateCounterCheckIn = body => {
  return {
    type: "updateCounterCheckIn",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/UpdateCounterCheckIn",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};


export const updateStatusCounterCheckIn = body => {
  return {
    type: "updateStatusCounterCheckIn",
    payload: {
      request: {
        method: "PUT",
        url: "/Setting/v1/updateStatusCounterCheckIn",
        data: querystring.stringify(body)
      },
      options: options
    }
  };
};
