import React, { Component } from "react";
import "./Dropdown.css";
import { Icon } from "antd";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAirType } from "../redux/actions/dropdown";
/*
null : mean empty
Option {
  name: option name
  id: key reference
  text: key reference or function
  label: label on button(multiple = true)
  filter: filter after get options
  preFilter filter befor transform to id and text
  sort: sort function after filter
}
*/

const VIEW_RANGE = 1000;
class DynamicDropdown extends Component {
  state = {
    stateOptions: [],
    viewOptions: null
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  getOption = name => {
    const { dropdown } = this.props;
    return name.split(".").reduce((obj, i) => obj[i], dropdown);
  };

  handleClickOutside = event => {
    if (this.refs.wrapperRef && !this.refs.wrapperRef.contains(event.target)) {
      this.refs.ddl && this.refs.ddl.classList.remove("show");
      setTimeout(() => {
        if (this.refs.wrapperRef)
          this.setState({ stateOptions: [], viewOptions: null });
      }, 200);
    }
  };

  toggleDropdown = () => {
    this.refs.ddl && this.refs.ddl.classList.toggle("show");
    this.refs.input && this.refs.input.focus();
    this.count = 0;
    setTimeout(() => {
      if (this.refs.wrapperRef) {
        const { options } = this.props;
        if (!options)
          return this.setState({ stateOptions: null, viewOptions: null });
        let list =
          options.dataLoad === 1
            ? options.dataService
            : this.getOption(options.name);
        if (options.preFilter) list = list.filter(options.preFilter);
        list = list.map(x => ({
          id: x[options.id || "id"],
          text: !options.text
            ? x.text
            : typeof options.text === "function"
            ? options.text(x)
            : x[options.text]
        }));
        if (options.filter) list = list.filter(options.filter);
        if (options.sort) list = list.sort(options.sort);
        this.setState({
          stateOptions: list,
          viewOptions: list.slice(0, VIEW_RANGE)
        });
      }
    }, 200);
  };

  filterFunction = () => {
    const { stateOptions } = this.state;
    const input = this.refs.input.value.toUpperCase();
    this.setState({
      viewOptions: stateOptions
        .filter(x => x && x.text && x.text.toUpperCase().indexOf(input) > -1)
        .slice(0, VIEW_RANGE)
    });
  };

  onSelect = data => {
    if (!data) {
      this.refs.input.value = "";
      return this.setState({ viewOptions: this.state.stateOptions });
    }
    let { multiple, selected, options } = this.props;
    if (!multiple) this.refs.ddl && this.refs.ddl.classList.remove("show");
    const op =
      options.dataLoad === 1
        ? options.dataService
        : this.getOption(options.name);
    const index = op.findIndex(x => x[options.id || "id"] === data.id);
    setTimeout(() => {
      let param = "";
      if (multiple) {
        if (!selected) selected = [];
        if (selected.indexOf(data.id) === -1) selected.push(data.id);
        param = selected.join();
      } else {
        selected = data.id;
        param = selected;
        this.setState({ stateOptions: [], viewOptions: null });
      }
      if (this.props.onSelect) this.props.onSelect(selected, op[index]);
      if (options.name === "callsignOptions" && !options.disableApi)
        this.props.getAirType(param);
    }, 200);
  };

  onRemove = (e, id) => {
    e.stopPropagation();
    let { selected } = this.props;
    if (!selected) selected = [];
    const index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
      if (this.props.onSelect) this.props.onSelect(selected);
    }
  };

  onClear = e => {
    e.stopPropagation();
    const { multiple } = this.props;
    const selected = multiple ? [] : null;
    if (this.props.onSelect) this.props.onSelect(selected);
  };

  renderMultiple = () => {
    const { selected, placeholder, options } = this.props;
    const list =
      (options.dataLoad === 1
        ? options.dataService
        : this.getOption(options.name)) || [];
    return (
      <div className="px-1 pt-1" style={{ cursor: "text" }}>
        <div className="mr-3" onClick={this.toggleDropdown}>
          {selected &&
            selected.map((v, i) => {
              const l = list.filter(x => x[options.id || "id"] === v)[0];
              let text = "";
              if (l) {
                if (!options.text) text = l.text;
                else {
                  text =
                    typeof options.text === "function"
                      ? options.text(l)
                      : l[options.text];
                }
              }
              return (
                <span
                  key={i}
                  title={text}
                  className="list-inline-item rounded px-1 mb-1"
                  style={{ background: "#c5f3fc" }}
                >
                  <span className="pr-1">
                    {options.label ? l[options.label] : text}
                  </span>
                  <FontAwesomeIcon
                    onClick={e => this.onRemove(e, v)}
                    style={{ cursor: "pointer" }}
                    size="sm"
                    icon="times"
                  />
                </span>
              );
            })}
          {(!selected || (selected && selected.length === 0)) && (
            <span style={{ fontSize: 14, color: "#C8C8C8" }} className="px-2">
              {placeholder}
            </span>
          )}
        </div>
        <div
          onClick={this.toggleDropdown}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: 0,
            top: "45%",
            transform: "translateY(-50%)"
          }}
        >
          <span className="list-inline-item rounded clear">
            <FontAwesomeIcon
              onClick={e => this.onClear(e)}
              style={{ cursor: "pointer", fontSize: "12px", color: "#66ccff" }}
              size="sm"
              icon="times"
            />
          </span>
          <Icon
            className="icon float-right align-middle clear-icon"
            type="down"
            style={{ fontSize: "12px", color: "#66ccff" }}
          />
        </div>
      </div>
    );
  };

  renderSingle = () => {
    const {
      btStyle,
      disabled,
      selected,
      className,
      placeholder,
      options
    } = this.props;
    let list =
      (options &&
        (options.dataLoad === 1
          ? options.dataService
          : this.getOption(options.name))) ||
      [];
    list = list.filter(x => x[options.id || "id"] === selected)[0];
    let text = "";
    if (list) {
      text =
        typeof options.text === "function"
          ? options.text(list)
          : list[options.text || "text"];
    }
    return (
      <span onClick={disabled ? null : this.toggleDropdown}>
        <button
          disabled={disabled}
          className={"dropbtn " + className + (disabled ? " disabled" : "")}
          style={btStyle}
          title={text || placeholder}
        >
          <span>{text || placeholder}</span>
        </button>
        <span className="list-inline-item rounded clear-single">
          <FontAwesomeIcon
            onClick={e => (disabled ? null : this.onClear(e))}
            style={{ cursor: "pointer", fontSize: "12px", color: "#66ccff" }}
            size="sm"
            icon="times"
          />
        </span>
        <Icon
          className="icon clear-single-icon"
          type="down"
          style={{ fontSize: "12px", color: "#66ccff" }}
        />
      </span>
    );
  };

  onKeyDown = e => {
    try {
      const { viewOptions } = this.state;
      const length = viewOptions && viewOptions.length;
      if (e.key === "ArrowUp") {
        if (this.count > 0) --this.count;
      } else if (e.key === "ArrowDown") {
        if (this.count < length + 1) this.count++;
      }
      if (e.key === "Enter") {
        this.onSelect(viewOptions[this.count - 1]);
      }
    } catch (e) {}
  };

  render() {
    const { language, multiple, isError } = this.props;
    const { viewOptions, stateOptions } = this.state;
    const searchText = language === "th" ? "ค้นหา" : "search";
    const stateLength = stateOptions ? stateOptions.length : 0;
    const viewLength = viewOptions ? viewOptions.length : 0;
    return (
      <div className="custom-dropdown border" ref="wrapperRef">
        <div
          style={{
            width: "100%",
            border: isError ? "solid 1px #f5222d" : "",
            borderRadius: 3
          }}
        >
          {multiple ? this.renderMultiple() : this.renderSingle()}
        </div>

        <div ref="ddl" className="dropdown-content">
          <input
            type="text"
            placeholder={searchText}
            ref="input"
            onKeyUp={this.filterFunction}
          />
          {!viewOptions && (
            <div className="text-center">
              <Icon type="sync" spin />
            </div>
          )}
          <div
            className="list"
            onKeyDown={this.onKeyDown}
          >
            {viewOptions &&
              viewOptions.map((data, i) => {
                return (
                  <div
                    key={i}
                    className="option"
                    onClick={this.onSelect.bind(null, data)}
                  >
                    {data.text}
                  </div>
                );
              })}
            {stateLength > viewLength && (
              <option
                title={"load all data"}
                onClick={this.onSelect.bind(null, null)}
              >
                {"more..."}
              </option>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language,
  dropdown: state.dropdown
});

const mapDispatchToProps = dispatch => ({
  getAirType: callsignId => dispatch(getAirType(callsignId))
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicDropdown);
