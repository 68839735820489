const useMock = false;
const mockPermission = {
  statistics: 2,

  flightdetail: 2,
  flightschedule: 2,
  flightslot: 2,
  counterslot: 2,

  operationreport: 2,

  formmanagement: 2,

  relationsetting: 2,
  airlinesetting: 2,
  callsignsetting: 2,
  airtypesetting: 2,

  airportsetting: 2,
  airporttypesetting: 2,
  zonenosetting: 2,
  regionsetting: 2,
  terminalsetting: 2,
  beltsetting: 2,
  gatesetting: 2,
  countercheckinsetting: 2,
  airportcountersetting: 2,
  parkingbaysetting: 2,
  parkingslotsetting: 2,

  flightstatussetting: 2,
  trafftypesetting: 2,
  countersetting: 2,

  landingsetting: 2,
  parkingsetting: 2,
  pscsetting: 2,
  appssetting: 2,

  department: 2,
  airline: 2,

  dashboard: 2
};
//FULL_ACCESS = 2
const READ_ONLY = 1,
  DENIED = 0,
  FULL_ACCESS = 2;

const isAccessable = (path, permission) => {
  try {
    let pm = sessionStorage.getItem("pm");
    if (pm) pm = JSON.parse(pm).permissionAccess;
    if (useMock) pm = mockPermission;
    if (!pm) return false;
    return pm[path] !== DENIED && pm[path] !== undefined;
  } catch (ex) {
    console.log(ex);
    return false;
  }
};

const isReadOnly = (path, permission) => {
  try {
    let pm = sessionStorage.getItem("pm");
    if (pm) pm = JSON.parse(pm).permissionAccess;
    if (useMock) pm = mockPermission;
    if (!pm) return false;
    return pm[path] === READ_ONLY;
  } catch (ex) {
    console.log(ex);
    return false;
  }
};

const isFullAcess = (path, permission) => {
  try {
    let pm = sessionStorage.getItem("pm");
    if (pm) pm = JSON.parse(pm).permissionAccess;
    if (useMock) pm = mockPermission;
    if (!pm) return false;
    return pm[path] === FULL_ACCESS;
  } catch (ex) {
    console.log(ex);
    return false;
  }
};

const getDescendantPage = menu => {
  const pages = require("./page").pages;
  let start = -1,
    end = -1,
    index = 0;
  for (let p of pages) {
    ++index;
    if (p.menu === "" && start !== -1) {
      end = index;
      break;
    }
    if (p.text === menu) start = index;
  }
  return pages.slice(start, end - 1);
};

const isAccessableMenu = menu => {
  const pages = getDescendantPage(menu);
  for (let p of pages) if (isAccessable(p.menu)) return true;
  return false;
};

module.exports = {
  isAccessable,
  isReadOnly,
  isAccessableMenu,
  isFullAcess
};
