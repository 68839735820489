const empty = {
  presence: {
    allowEmpty: false,
    message: 'require'
  }
}

const Constraints = {
  airportId: empty,
  terminalId: empty,
}

const table = {
  counterEN: empty,
  counterTH: empty,
}

module.exports = {
  Constraints,
  table
}