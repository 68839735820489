import React, { Component } from "react";
import Render from "../../../components/Renderer";
import { Table, Divider, Switch, message, Popover } from "antd";
import { isReadOnly } from "../../../utils/permission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validatejs from "validate.js";
import { table as tConstraints, Constraints } from "./Constraints";
import { connect } from "react-redux";
import { getParkingBay, getAirport } from "../../../redux/actions/dropdown";
import {
  insertParkingSlot,
  deleteParkingSlot,
  getListSettingParkingSlot,
  updateDisplayStatusParkingSlot
} from "../../../redux/actions/setting";

class ParkingSlotAdd extends Component {
  state = {
    form: {},
    data: [],
    disabledSave: false,
    visible: {},
    filterAirport: []
  };

  componentDidMount() {
    const data = [{ key: 1, isError: true, display: true }];
    this.setState({ data, errors: validatejs({}, Constraints) });
    this.setFilterAirport();
  }

  setFilterAirport = () => {
    this.props.getAirport().then(res => {
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        this.setState({
          filterAirport: data
        });
      }
    });
  };

  search = () => {
    let { form } = this.state;
    let param = "";
    if (form.airportId !== undefined) {
      param = "airportId=" + form.airportId;
      this.getList(param);
    }
  };

  getList = param => {
    this.props.getListSettingParkingSlot(param).then(res => {
      this.setState({ isLoading: false });
      if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
        let data = res.payload.data.data;
        data.push({ statusDisplay: 1 });
        data.forEach((x, i) => {
          x.key = i;
          x.display = x.statusDisplay === 1;
        });
        this.setState({ data });
      }
    });
  };

  onDelete = (item, index) => {
    let { data } = this.state;
    data.splice(index, 1);
    this.setState({ data });
    if (item.parkingSlotId) this.onDeleteOnline(item);
    let { visible } = this.state;
    visible[index] = false;
    this.setState({ visible });
  };

  onAdd = () => {
    let { data } = this.state;
    data.push({ key: data.length + 1, display: true });
    this.setState({ data });
  };

  onChange = ({ name, value, error }) => {
    // if (!value)
    //   return this.setState({
    //     data: [{ key: 1, isError: true, display: true }]
    //   });
    let { form, errors } = this.state;
    form[name] = value;
    if (errors === undefined) errors = {};
    if (error === undefined) delete errors[name];
    else errors[name] = error.join();
    if (Object.keys(errors).length === 0) errors = undefined;
    this.setState({ form, errors }, this.search);
  };

  onChangeTable = (index, name, value) => {
    let { data } = this.state;
    data[index][name] = value;
    for (let item of data) {
      const valid = validatejs(item, tConstraints);
      item.isError = valid !== undefined;
    }
    this.setState({ data });
  };

  renderInput = (name, row, index, isNumber = false) => {
    var template = {
      type: "input",
      name: name,
      number: isNumber,
      value: row[name],
      disabled: row.parkingSlotId !== undefined,
      placeholder: ""
    };
    return Render(template, ({ name, value }) =>
      this.onChangeTable(index, name, value)
    );
  };

  onDeleteOnline = item => {
    this.props.deleteParkingSlot(item.parkingSlotId).then(res => {
      if (res.status === 400) {
        message.error("ไม่สามารถลบข้อมูลได้");
      } else if (
        res &&
        res.payload.status === 200 &&
        res.type.endsWith("SUCCESS")
      ) {
        message.success("Delete Parking Slot success");
        this.props.getParkingBay();
      }
    });
  };

  onSave = () => {
    this.setState({ disabledSave: true }, () => {
      const { form, data } = this.state;
      const clean = data.filter(
        x => !x.isError && x.parkingSlotId === undefined
      );
      const body = {
        airportId: form.airportId,
        listParkingSlot: clean.map(d => ({
          parkingSlotNameTH: d.parkingSlotNameTH,
          parkingSlotNameEN: d.parkingSlotNameEN,
          remark: d.remark,
          isEnable: d.display ? 1 : 0,
          aircraftWidth: d.aircraftWidth,
          aircraftLength: d.aircraftLength,
        }))
      };
      this.props.insertParkingSlot(body).then(res => {
        setTimeout(() => {
          this.setState({ disabledSave: false });
        }, 500);
        if (res.status === 400) {
          message.error("ไม่สามารถเพิ่มข้อมูลได้");
        } else if (
          res &&
          res.payload.status === 201 &&
          res.type.endsWith("SUCCESS")
        ) {
          message.success("Insert Parking Slot success");
          this.props.getParkingBay();
          this.props.onClose(this.props.name);
        }
      });
    });
  };

  edit = item => this.props.onEdit(this.props.name, item);

  refresh = () => {
    this.props.onRefreshDone();
    setTimeout(() => {
      this.search();
    }, 200);
  };

  onCheckChange = (item, checked, index) => {
    if (item.parkingSlotId) {
      const body = {
        parkingSlotId: item.parkingSlotId,
        statusDisplay: checked ? 1 : 0
      };
      this.props.updateDisplayStatusParkingSlot(body).then(res => {
        if (res && res.payload.status === 200 && res.type.endsWith("SUCCESS")) {
          message.success("Update status success");
          this.search(true);
        }
      });
    } else {
      let { data } = this.state;
      data[index].display = checked;
      this.setState({ data });
    }
  };

  visibleChange = item => {
    let { visible } = this.state;
    visible[item] = false;
    this.setState({ visible });
  };

  render() {
    const { form, data, errors, disabledSave, filterAirport } = this.state;
    const { refresh } = this.props;
    if (refresh) this.refresh();
    const readOnly = isReadOnly("airtypesetting");
    const template = [
      {
        type: "select",
        name: "airportId",
        label: "Airport",
        constraint: Constraints,
        value: form.airportId,
        placeholder: "Select Airport...",
        options: {
          name: "airPortOptions",
          id: "airportID",
          text: "airportName",
          dataLoad: 1,
          dataService: filterAirport,
          preFilter: q => q.statusDisplay === 1
        }
      }
    ];
    const columns = [
      {
        title: "Parking Slot (ENG)",
        key: "parkingSlotNameEN",
        dataIndex: "parkingSlotNameEN",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("parkingSlotNameEN", row, index)
      },
      {
        title: "Parking Slot (TH)",
        dataIndex: "parkingSlotNameTH",
        key: "parkingSlotNameTH",
        width: 150,
        render: (text, row, index) =>
          this.renderInput("parkingSlotNameTH", row, index)
      },
      {
        title: "Width (Meter)",
        dataIndex: "aircraftWidth",
        key: "aircraftWidth",
        width: 110,
        render: (text, row, index) =>
          this.renderInput("aircraftWidth", row, index, true)
      },
      {
        title: "Length (Meter)",
        dataIndex: "aircraftLength",
        key: "aircraftLength",
        width: 110,
        render: (text, row, index) =>
          this.renderInput("aircraftLength", row, index, true)
      },
      {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        width: 150,
        render: (text, row, index) => this.renderInput("remark", row, index)
      },
      {
        title: "Display (On/Off)",
        key: "display",
        width: 150,
        render: (text, row, index) => (
          <Switch
            disabled={readOnly}
            onChange={checked => this.onCheckChange(row, checked, index)}
            checked={row.display}
          />
        )
      },
      {
        title: "Edit",
        key: "edit",
        width: 80,
        render: item =>
          item.parkingSlotId && (
            <button
              disabled={readOnly}
              onClick={() => this.edit(item)}
              type="button"
              className="btn btn-outline-warning btn-sm"
            >
              <FontAwesomeIcon icon="pen" />
            </button>
          )
      },
      {
        title: "",
        key: "add",
        width: 80,
        render: (text, row, index) =>
          index + 1 === data.length ? (
            <button
              type="button"
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                this.onAdd(index);
              }}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          ) : (
            <Popover
              visible={this.state.visible[index]}
              onVisibleChange={_visible => {
                let { visible } = this.state;
                visible[index] = _visible;
                this.setState({ visible });
              }}
              content={
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mr-2"
                    onClick={() => {
                      this.onDelete(row, index);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => this.visibleChange(index)}
                  >
                    Cancel
                  </button>
                </div>
              }
              title="Are you sure?"
              trigger="click"
            >
              <button
                type="button"
                className="btn btn-sm btn-outline-danger"
                // onClick={() => {
                //   this.onDelete(row, index);
                // }}
                onClick={this.delete}
              >
                <FontAwesomeIcon icon="trash" />
              </button>
            </Popover>
          )

        // <button
        //   onClick={() => {
        //     index + 1 === data.length
        //       ? this.onAdd(index)
        //       : this.onDelete(row, index);
        //   }}
        //   type="button"
        //   className={
        //     "btn btn-sm " +
        //     (index + 1 === data.length
        //       ? "btn-outline-success"
        //       : "btn-outline-danger")
        //   }
        // >
        //   <FontAwesomeIcon
        //     icon={index + 1 === data.length ? "plus" : "trash"}
        //   />
        // </button>
      }
    ];
    const sum = columns.reduce((a, b) => a + b.width, 0);
    const table = (
      <Table
        style={{ minWidth: 600 }}
        pagination={false}
        scroll={{ x: sum + "px" }}
        className="tbody-center bg-white"
        rowClassName={(record, index) => {
          if (record.isError && index !== data.length - 1) return "bg-danger";
        }}
        columns={columns}
        dataSource={data}
      />
    );
    return (
      <div className="card card-body card-body-component">
        <button
          type="button"
          onClick={() => this.props.onClose(this.props.name)}
          className="btn btn-success"
          style={{ width: 100 }}
        >
          <FontAwesomeIcon icon="angle-double-left" />
          <span className="ml-2">Back</span>
        </button>
        {template.map((data, i) => {
          return (
            <div key={i} className="form-row form-inline mb-2 col-12 px-0">
              <label className="col-lg-2 col-md-3 col-sm-4 col-5 justify-content-end">
                {data.label} :
              </label>
              <div className="col-lg-4 col-md-6 col-sm-8 col-12">
                {Render(data, this.onChange)}
              </div>
            </div>
          );
        })}
        {table}
        <Divider />
        {/* footer */}
        <div className="text-center mt-2">
          <button
            onClick={() => this.props.onClose(this.props.name)}
            className="btn btn-light mr-2"
          >
            Cancel
          </button>
          <button
            disabled={errors !== undefined || disabledSave}
            onClick={this.onSave}
            className="btn btn-success mr-2"
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.language
});

const mapDispatchToProps = dispatch => ({
  getListSettingParkingSlot: param =>
    dispatch(getListSettingParkingSlot(param)),
  insertParkingSlot: body => dispatch(insertParkingSlot(body)),
  deleteParkingSlot: id => dispatch(deleteParkingSlot(id)),
  getParkingBay: () => dispatch(getParkingBay()),
  updateDisplayStatusParkingSlot: body =>
    dispatch(updateDisplayStatusParkingSlot(body)),
  getAirport: () => dispatch(getAirport())
});

export default connect(mapStateToProps, mapDispatchToProps)(ParkingSlotAdd);
